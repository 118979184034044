const APP_ENV = process.env.REACT_APP_ENV;

const dev = {
  base_url: `https://cb-order-tracking-api-ukxjb66ceq-ew.a.run.app`
};

const prod = {
  base_url: `https://cb-order-tracking-api-f5dovyimaq-ew.a.run.app`
};

export const config = {
  uri: APP_ENV === 'production' ? prod : dev
};
