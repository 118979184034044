// @ts-check
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import TrackingPage from '../../features/tracking/presentation/pages/tracking-page';
import { PageNotFoundComponent } from './components/page-not-found-component';

// import NavBarComponent from './components/navbar-component';

const routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/tracking/:id" component={TrackingPage} />
        <Route component={PageNotFoundComponent} />
      </Switch>
    </Router>
  );
};

export default routes;
