// @ts-check
import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import './page-not-found.css';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export function PageNotFoundComponent() {
  return (
    <React.Fragment>
      <CssBaseline />

      <section className="page_404">
        <Container>
          <Grid container spacing={2} justify="center">
            <Grid item sm={10} xs={12} alignContent="center" alignItems="center">
              <Box justifyContent="center" textAlign="center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Looks like you're lost</h3>

                  <p>the page you are looking for not avaible!</p>

                  {/* <a href="" className="link_404">
                    Go to Home
                  </a> */}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
}
