// @ts-check
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { LiveStatus } from './live-status';
import Grid from '@material-ui/core/Grid';
import { computeDistance } from './compute-distance';

const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'rgb(255 255 255 / 90%)',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  },
  paperorg: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    // marginBottom: theme.spacing(1),
    textAlign: 'center',
    height: '2rem',
    objectFit: 'contain'
  },
  eta: {
    textAlign: 'right'
  },
  logo: {
    height: '1.5rem',
    objectFit: 'contain'
  }
}));

function DriverStatus() {
  const classes = useStyles();

  const [eta, setEta] = useState('');
  const { tracking } = useSelector((state) => state.tracking);
  const { order } = useSelector((state) => state.order);
  const status = order.status || 'default';

  const { driver } = useSelector((state) => state.driver);

  useEffect(() => {
    if (driver) {
      const travel = computeDistance(order.location, driver.location);
      let time = (travel.distance / (60 * 1000)) * 60;

      var d1 = new Date(),
        d2 = new Date(d1);
      d2.setMinutes(d1.getMinutes() + Math.round(time));

      const t12hr = d2.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });

      setEta(t12hr);
    }
  }, [order.location, driver]);

  switch (status) {
    case 'in_transit':
      return (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <small>
              <b>{tracking.driver.name} is heading your way</b>
            </small>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.eta}>
              <small>
                <b>ETA - {eta}</b>
              </small>
            </div>
          </Grid>
        </Grid>
      );
    case 'arrived':
      return (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <small>
              <b>{tracking.driver.name} has arrived</b>
            </small>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.eta}>
              <small>
                <b>ETA - {eta}</b>
              </small>
            </div>
          </Grid>
        </Grid>
      );
    case 'delivered':
      return (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <small>
              <b>Order delivered</b>
            </small>
          </Grid>
          <Grid item xs={4}>
            {/* <div className={classes.eta}>
              <small>
                <b>ETA - 19:04</b>
              </small>
            </div> */}
          </Grid>
        </Grid>
      );

    default:
      return (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <small>
              <b>Obert is heading your way</b>
            </small>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.eta}>
              <small>
                <b>ETA - --:--</b>
              </small>
            </div>
          </Grid>
        </Grid>
      );
  }
}

export function InformationComponent() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className={classes.paper}>
              <LiveStatus />
              <div>
                <DriverStatus />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
