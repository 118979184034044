import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import order from '../../features/order/presentation/redux/reducers/order-reducer';
import driver from '../../features/order/presentation/redux/reducers/driver-reducer';
import tracking from '../../features/tracking/presentation/redux/reducers/tracking-reducer';
import rating from '../../features/rating/presentation/redux/reducers/rating-reducer';

const reducers = combineReducers({ order, driver, tracking, rating });

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
export type AppState = ReturnType<typeof reducers>;
