// @ts-check
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import Grid from '@material-ui/core/Grid';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Avatar, Box, Divider, Typography } from '@material-ui/core';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { driverRatingAction } from '../redux/actions/rating-actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'rgb(255 255 255 / 90%)',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  },
  paperorg: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    // marginBottom: theme.spacing(1),
    textAlign: 'center',
    height: '2rem',
    objectFit: 'contain'
  },
  eta: {
    textAlign: 'right'
  },
  logo: {
    height: '1.5rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  ratingDiv: {
    textAlign: 'center'
  },
  rating: {
    fontSize: '3rem'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    marginTop: theme.spacing(3)
  },
  userAvatarContainer: {
    justifyContent: 'center',
    display: 'flex'
  },
  userAvatar: {
    width: '6rem',
    height: '6rem'
  },
  icon: {
    fontSize: '4rem',
    cursor: 'pointer',
    border: '1px solid',
    padding: theme.spacing(2),
    borderRadius: '50%'
  },
  heading: {
    margin: theme.spacing(2)
  }
}));

export function DriverRatingComponent() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { tracking } = useSelector((state) => state.tracking);
  const { loading, error } = useSelector((state) => state.rating);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12} justify="center">
            <div className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <small>
                    <b>Rate driver</b>
                  </small>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.eta}>
                    <KeyboardArrowDownIcon />
                  </div>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <Box textAlign="center" justifyContent="center">
                <div className={classes.userAvatarContainer}>
                  <Avatar
                    className={classes.userAvatar}
                    alt={`${tracking.driver.name} `}
                    src={tracking.driver.image || '/static/images/avatar/1.jpg'}
                  />
                </div>
              </Box>

              <Typography variant="h6" component="h6" align="center" className={classes.heading}>
                {`${tracking.driver.name} `}
              </Typography>

              <Grid container spacing={0} justify="center">
                <Grid item xs={6} justify="center">
                  <Box textAlign="center">
                    <ThumbDownIcon
                      className={classes.icon}
                      fontSize="large"
                      onClick={() => dispatch(driverRatingAction(tracking.id, 'down'))}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} justify="center">
                  <Box textAlign="center">
                    <ThumbUpIcon
                      className={classes.icon}
                      fontSize="large"
                      onClick={() => dispatch(driverRatingAction(tracking.id, 'up'))}
                    />
                  </Box>
                </Grid>
              </Grid>

              {error && (
                <div>
                  <span className={classes.errorText}>{error.message}</span>
                </div>
              )}
            </div>

            {loading && <LinearProgress />}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
