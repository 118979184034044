import { Dispatch } from 'redux';

import { RatingRepositoryImpl } from '../../../data/repositories/rating-repository-impl';
import { RatingUsecaseImpl } from '../../../domain/usecases/rating-usecase';

import {
  RATING_LOAD_REQUEST,
  RATING_LOAD_SUCCESS,
  RATING_LOAD_FAILURE
} from '../types/rating-types';

export const getRatingAction = (tracking_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RATING_LOAD_REQUEST });

    try {
      const ratingRepository = new RatingRepositoryImpl();
      const ratingUsecase = new RatingUsecaseImpl(ratingRepository);

      const result = await ratingUsecase.GetRating(tracking_id);

      dispatch({ type: RATING_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: RATING_LOAD_FAILURE, error });
    }
  };
};

export const starRatingAction = (tracking_id: string, rating: number, improvements: string[]) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RATING_LOAD_REQUEST });

    try {
      const ratingRepository = new RatingRepositoryImpl();
      const ratingUsecase = new RatingUsecaseImpl(ratingRepository);
      const result = await ratingUsecase.StarRating(tracking_id, rating);
      await ratingUsecase.ImprovementRating(tracking_id, improvements);

      dispatch({ type: RATING_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: RATING_LOAD_FAILURE, error });
    }
  };
};

export const feedbackRatingAction = (tracking_id: string, feedback: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RATING_LOAD_REQUEST });

    try {
      const ratingRepository = new RatingRepositoryImpl();
      const ratingUsecase = new RatingUsecaseImpl(ratingRepository);

      const result = await ratingUsecase.FeedbackRating(tracking_id, feedback);

      dispatch({ type: RATING_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: RATING_LOAD_FAILURE, error });
    }
  };
};

export const driverRatingAction = (tracking_id: string, value: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: RATING_LOAD_REQUEST });

    try {
      const ratingRepository = new RatingRepositoryImpl();
      const ratingUsecase = new RatingUsecaseImpl(ratingRepository);

      const result = await ratingUsecase.DriverRating(tracking_id, value);

      dispatch({ type: RATING_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: RATING_LOAD_FAILURE, error });
    }
  };
};
