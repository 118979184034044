export class Rating {
  id: string;
  step: number;
  numerical?: number;
  feedback?: string;
  driver?: string;
  improvements?: string[];

  constructor(args: any) {
    const states = ['numerical' in args, 'feedback' in args, 'driver' in args];

    this.id = args.id;
    this.numerical = args.numerical;
    this.feedback = args.feedback;
    this.driver = args.driver;
    this.improvements = args.improvements;
    this.step = states.indexOf(false);
  }
}
