import { Rating } from '../entities/rating-entitiy';
import { RatingRepository } from '../repositories/rating-repository';

interface RatingUsecase {
  GetRating(tracking_id: string): Promise<Rating>;
  StarRating(tracking_id: string, rating: number): Promise<Rating>;
  FeedbackRating(tracking_id: string, feedback: string): Promise<Rating>;
  ImprovementRating(tracking_id: string, values: string[]): Promise<Rating>;
  DriverRating(tracking_id: string, value: string): Promise<Rating>;
}

export class RatingUsecaseImpl implements RatingUsecase {
  ratingRepository: RatingRepository;

  constructor(tr: RatingRepository) {
    this.ratingRepository = tr;
  }

  GetRating(tracking_id: string): Promise<Rating> {
    return this.ratingRepository.GetRating(tracking_id);
  }

  StarRating(tracking_id: string, rating: number): Promise<Rating> {
    return this.ratingRepository.StarRating(tracking_id, rating);
  }

  FeedbackRating(tracking_id: string, feedback: string): Promise<Rating> {
    return this.ratingRepository.FeedbackRating(tracking_id, feedback);
  }

  ImprovementRating(tracking_id: string, values: string[]): Promise<Rating> {
    return this.ratingRepository.ImprovementRating(tracking_id, values);
  }

  DriverRating(tracking_id: string, value: string): Promise<Rating> {
    return this.ratingRepository.DriverRating(tracking_id, value);
  }
}
