import { Dispatch } from 'redux';

import { OrderRepositoryImpl } from '../../../data/repositories/order-repository-impl';
import { OrderUsecaseImpl } from '../../../domain/usecases/order-usecases';

import { ORDER_LOAD_REQUEST, ORDER_LOAD_SUCCESS, ORDER_LOAD_FAILURE } from '../types/order-types';
import {
  DRIVER_LOAD_REQUEST,
  DRIVER_LOAD_SUCCESS,
  DRIVER_LOAD_FAILURE
} from '../types/driver-types';

export const getOrderAction = (client_id: string, order_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ORDER_LOAD_REQUEST });

    try {
      const orderRepository = new OrderRepositoryImpl();
      const orderUsecase = new OrderUsecaseImpl(orderRepository);

      const ps = orderUsecase.GetOrder(client_id, order_id);
      const unsubscribe = ps.subscribe('order', (data: any) => {
        dispatch({ type: ORDER_LOAD_SUCCESS, payload: data });
      });

      unsubscribe();
    } catch (error) {
      dispatch({ type: ORDER_LOAD_FAILURE, error });
    }
  };
};

export const getDriverAction = (driver_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: DRIVER_LOAD_REQUEST });

    try {
      const orderRepository = new OrderRepositoryImpl();
      const orderUsecase = new OrderUsecaseImpl(orderRepository);

      const ps = orderUsecase.LiveLocation(driver_id);
      ps.subscribe('live-location', (data: any) => {
        dispatch({ type: DRIVER_LOAD_SUCCESS, payload: data });
      });
    } catch (error) {
      dispatch({ type: DRIVER_LOAD_FAILURE, error });
    }
  };
};
