class Geopoint {
  latitude: number;
  longitude: number;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export class Order {
  id: string;
  status: string;
  address: string;
  location: Geopoint;

  constructor(args: any) {
    this.id = args.id;
    this.status = args.status;
    this.address = args.address;
    this.location = new Geopoint(args.location.latitude, args.location.longitude);
  }
}
