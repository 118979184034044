import { Dispatch } from 'redux';

import { TrackingRepositoryImpl } from '../../../data/repositories/tracking-repository-impl';
import { TrackingUsecaseImpl } from '../../../domain/usecases/tracking-usecase';

import {
  TRACKING_LOAD_REQUEST,
  TRACKING_LOAD_SUCCESS,
  TRACKING_LOAD_FAILURE
} from '../types/tracking-types';

export const getTrackingDetailsAction = (tracking_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: TRACKING_LOAD_REQUEST });

    try {
      const trackingRepository = new TrackingRepositoryImpl();
      const trackingUsecase = new TrackingUsecaseImpl(trackingRepository);

      const result = await trackingUsecase.GetTrackingDetails(tracking_id);

      dispatch({ type: TRACKING_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: TRACKING_LOAD_FAILURE, error });
    }
  };
};
