// @ts-check
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Rating from '@material-ui/lab/Rating';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CircularProgress, Divider } from '@material-ui/core';
import { starRatingAction } from '../redux/actions/rating-actions';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'rgb(255 255 255 / 90%)',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  },
  paperorg: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    // marginBottom: theme.spacing(1),
    textAlign: 'center',
    height: '2rem',
    objectFit: 'contain'
  },
  eta: {
    textAlign: 'right'
  },
  logo: {
    height: '1.5rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  ratingDiv: {
    textAlign: 'center'
  },
  rating: {
    fontSize: '3rem'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    marginTop: theme.spacing(3)
  }
}));

export function StarRatingComponent() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [improvement, setImprovement] = React.useState('n/a');

  const { tracking } = useSelector((state) => state.tracking);
  const { loading, error } = useSelector((state) => state.rating);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12} justify="center">
            <div className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <small>
                    <b>Leave your rating</b>
                  </small>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.eta}>
                    <KeyboardArrowDownIcon />
                  </div>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <div className={classes.ratingDiv}>
                <Rating
                  className={classes.rating}
                  name="simple-controlled"
                  size="large"
                  value={value}
                  onChange={(event, newValue) => {
                    event.preventDefault();
                    // @ts-ignore
                    setValue(newValue);
                    if (newValue === 5) {
                      setImprovement('n/a');
                    }
                  }}
                />
              </div>

              {value > 0 && value < 5 && (
                <>
                  <div className={classes.ratingDiv}>
                    <br />
                    <br />
                    <FormLabel component="label">What should be improved</FormLabel>
                    <br />
                    <br />
                    <Divider className={classes.divider} />
                  </div>

                  <div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={improvement}
                        // onChange={handleChange}
                        onChange={(event, newValue) => {
                          event.preventDefault();
                          // @ts-ignore
                          setImprovement(newValue);
                        }}
                      >
                        {tracking.ratings.map((item, key) => (
                          <FormControlLabel
                            key={key}
                            value={item}
                            control={<Radio />}
                            label={item}
                          />
                        ))}

                        {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                      </RadioGroup>
                    </FormControl>
                    <Divider className={classes.divider} />
                  </div>
                </>
              )}

              {error && (
                <div>
                  <span className={classes.errorText}>{error.message}</span>
                </div>
              )}

              <div>
                {!loading && (
                  <>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      fullWidth
                      className={classes.margin}
                      disabled={value < 1 || (value < 5 && improvement === 'n/a')}
                      onClick={() => dispatch(starRatingAction(tracking.id, value, [improvement]))}
                    >
                      Continue
                    </Button>
                  </>
                )}

                {loading && (
                  <Button
                    className={classes.margin}
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth={true}
                  >
                    <CircularProgress color="inherit" size={26} />
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
