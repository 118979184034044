// @ts-check
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CircularProgress, Divider } from '@material-ui/core';
import { feedbackRatingAction } from '../redux/actions/rating-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  paper: {
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'rgb(255 255 255 / 90%)',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  },
  paperorg: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    // marginBottom: theme.spacing(1),
    textAlign: 'center',
    height: '2rem',
    objectFit: 'contain'
  },
  eta: {
    textAlign: 'right'
  },
  logo: {
    height: '1.5rem',
    objectFit: 'contain'
  },
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  errorText: {
    color: '#f44336',
    textAlign: 'center',
    display: 'block',
    marginTop: theme.spacing(3)
  }
}));

function CreateUserForm(props) {
  const classes = useStyles();

  const { error, loading } = useSelector((state) => state.rating);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = '') => ({
    name: name,
    value: typeof values[name] !== 'undefined' ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : ''
  });

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <TextField
        {...formikProps('feedback')}
        id="outlined-multiline-static"
        label="Feedback"
        multiline
        rows={4}
        variant="outlined"
        fullWidth
      />

      {error && (
        <div>
          <span className={classes.errorText}>{error.message}</span>
        </div>
      )}

      <div>
        {!loading && (
          <>
            <Button
              className={classes.margin}
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          </>
        )}

        {loading && (
          <Button
            className={classes.margin}
            variant="contained"
            size="large"
            color="primary"
            fullWidth={true}
          >
            <CircularProgress color="inherit" size={26} />
          </Button>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  feedback: Yup.string('Leave your feedback').required('Feedback is required')
});

function CreateUserFormValidation() {
  const dispatch = useDispatch();

  const { tracking } = useSelector((state) => state.tracking);

  const values = {
    feedback: ''
  };

  const submit = (data) => {
    dispatch(feedbackRatingAction(tracking.id, data.feedback));
  };

  return (
    <Formik initialValues={values} validationSchema={validationSchema} onSubmit={submit}>
      {(props) => <CreateUserForm {...props} />}
    </Formik>
  );
}

export function FeedbackRatingComponent() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12} justify="center">
            <div className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <small>
                    <b>Leave your feedback</b>
                  </small>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.eta}>
                    <KeyboardArrowDownIcon />
                  </div>
                </Grid>
              </Grid>

              <Divider className={classes.divider} />

              <CreateUserFormValidation />
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
