import { Driver } from '../entities/driver-entity';
import { PublisherSubscriber } from '../entities/publisher-subscriber-entity';
import { OrderRepository } from '../repositories/order-repository';

interface OrderUsecase {
  GetOrder(client_id: string, order_id: string): PublisherSubscriber;
  GetDriver(driver_id: string): Promise<Driver>;
  LiveLocation(driver_id: string): any;
}

export class OrderUsecaseImpl implements OrderUsecase {
  orderRepository: OrderRepository;

  constructor(or: OrderRepository) {
    this.orderRepository = or;
  }

  GetOrder(client_id: string, order_id: string): PublisherSubscriber {
    return this.orderRepository.GetOrder(client_id, order_id);
  }

  GetDriver(driver_id: string): Promise<Driver> {
    throw new Error('Method not implemented.');
  }

  LiveLocation(driver_id: string): PublisherSubscriber {
    return this.orderRepository.LiveLocation(driver_id);
  }
}
