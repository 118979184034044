// @ts-check
import React from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  progress: {
    width: '100%'
  }
}));

function StatusDefault() {
  return (
    <>
      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={0} />
      </Grid>

      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={0} />
      </Grid>

      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={0} />
      </Grid>
    </>
  );
}

function StatusInTransit() {
  return (
    <>
      <Grid item xs={4}>
        <LinearProgress />
      </Grid>

      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={0} />
      </Grid>

      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={0} />
      </Grid>
    </>
  );
}

function StatusArrived() {
  return (
    <>
      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={100} />
      </Grid>

      <Grid item xs={4}>
        <LinearProgress />
      </Grid>

      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={0} />
      </Grid>
    </>
  );
}

function StatusDelivered() {
  return (
    <>
      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={100} />
      </Grid>

      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={100} />
      </Grid>

      <Grid item xs={4}>
        <LinearProgress variant="determinate" value={100} />
      </Grid>
    </>
  );
}

function OrderStatus() {
  const { order } = useSelector((state) => state.order);

  const status = order.status || 'default';

  switch (status) {
    case 'in_transit':
      return <StatusInTransit />;
    case 'arrived':
      return <StatusArrived />;
    case 'delivered':
      return <StatusDelivered />;

    default:
      return <StatusDefault />;
  }
}

export function LiveStatus() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={2}>
        <OrderStatus />
      </Grid>
    </div>
  );
}
