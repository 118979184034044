// @ts-check
import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader as MapLoader } from 'google-maps';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const mapStyle = [
  // {
  //   featureType: 'road',
  //   elementType: 'labels',
  //   stylers: [{ visibility: 'off' }]
  // }
];

export const NonTrackingMapComponent = () => {
  const { order } = useSelector((state) => state.order);

  const mapRef = useRef();
  const [mapmarker, setMapMarker] = useState(undefined);

  const mapping = useCallback(async () => {
    const geopoint = { lat: -26.2041, lng: 28.0473 };
    const options = {
      libraries: ['places', 'geometry']
    };
    const loader = new MapLoader(GOOGLE_MAPS_API_KEY, options);
    const google = await loader.load();

    const boundary = google.maps;
    let bounds = new boundary.LatLngBounds();

    // @ts-ignore
    const map = new google.maps.Map(mapRef.current, {
      center: { lat: -26.2041, lng: 28.0473 },
      // zoom: 13,
      scaleControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: true,
      draggable: true,
      scrollwheel: false,
      disableDoubleClickZoom: false
    });

    //set style
    map.set('styles', mapStyle);

    let icon = {
      url: `/bluestore.png`, // url
      scaledSize: new google.maps.Size(35, 35) // scaled size
    };

    const origin = new google.maps.Marker({
      map: map,
      icon: icon,
      anchorPoint: new google.maps.Point(0, 0),
      draggable: false,
      animation: google.maps.Animation.DROP
    });
    origin.setVisible(true);
    origin.setPosition(geopoint);
    bounds.extend(geopoint);

    map.fitBounds(bounds);
    // map.panToBounds(bounds);
    map.setZoom(18);
    // @ts-ignore
    setMapMarker({ map, origin, boundary });
    return;
  }, []);

  useEffect(() => {
    mapping();
  }, [mapping]);

  // order tracking - live destination location
  useEffect(() => {
    if (order) {
      const { location } = order;

      if (mapmarker) {
        const { map, origin, boundary } = mapmarker;
        let bounds = new boundary.LatLngBounds();

        const geopoint = { lat: location.latitude, lng: location.longitude };
        origin.setVisible(true);
        origin.setPosition(geopoint);
        bounds.extend(origin.getPosition());
        map.fitBounds(bounds);
        // map.panToBounds(bounds);
        map.setZoom(18);
      }
    }
  }, [order, mapmarker]);

  return (
    <>
      <div className="uk-dislpay-block" style={{ height: '100%', width: '100%' }}>
        <div>
          <div
            id="map"
            // @ts-ignore
            ref={mapRef}
            style={{ height: 'calc(100vh - 3rem)', width: '100wh' }}
          ></div>
        </div>
      </div>
    </>
  );
};
