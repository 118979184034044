import { Tracking } from '../entities/tracking-entity';
import { TrackingRepository } from '../repositories/tracking-repository';

interface TrackingUsecase {
  GetTrackingDetails(tracking_id: string): Promise<Tracking>;
}

export class TrackingUsecaseImpl implements TrackingUsecase {
  trackingRepository: TrackingRepository;

  constructor(tr: TrackingRepository) {
    this.trackingRepository = tr;
  }

  GetTrackingDetails(tracking_id: string): Promise<Tracking> {
    return this.trackingRepository.GetTrackingDetails(tracking_id);
  }
}
