// @ts-check
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Container from '@material-ui/core/Container/Container';

import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(0)
  },
  logo: {
    height: '1.9rem'
  },
  edgePadding: {
    padding: theme.spacing(0)
  },
  section: {
    display: 'flex'
  }
}));

function PrimarySearchAppBar() {
  const history = useHistory();
  const classes = useStyles();

  const { tracking } = useSelector((state) => state.tracking);

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" color="default" elevation={0}>
        <Container>
          <Toolbar className={classes.edgePadding}>
            <Button color="inherit" onClick={() => history.push('/')}>
              <img
                className={classes.logo}
                src={tracking.organization.logo}
                // src={
                //   'https://storage.googleapis.com/cowabunga-public-images/client-logos/56d758c4-0af4-11eb-b201-9fc21da833a9-checkers-logo.svg'
                // }
                alt="logo"
              />
            </Button>

            <div className={classes.grow} />
            <div className={classes.section}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                // onClick={() => history.push('/account')}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default function NavBarComponent(props) {
  return (
    <>
      <PrimarySearchAppBar />
      {props.children}
    </>
  );
}
