import fetch from 'isomorphic-fetch';
import { config } from '../../../../core/config/config';

export const starRating = async (tracking_id: string, rating: number): Promise<any> => {
  try {
    const uri = `${config.uri.base_url}/rating/star`;

    const options = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ tracking_id, rating })
    };

    const response = await fetch(uri, options);
    const data = await response.json();

    if (data.message) {
      throw new Error(data.message);
    }

    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
