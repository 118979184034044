import {
  TRACKING_LOAD_REQUEST,
  TRACKING_LOAD_SUCCESS,
  TRACKING_LOAD_FAILURE
} from '../types/tracking-types';

const initialState = {
  loading: false,
  success: false,
  error: null,
  tracking: null
};

function tracking(state = initialState, action: any = null) {
  switch (action.type) {
    case TRACKING_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };

    case TRACKING_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };

    case TRACKING_LOAD_SUCCESS:
      return {
        ...state,
        tracking: action.payload,
        loading: false,
        success: true,
        error: null
      };

    default:
      return state;
  }
}

export default tracking;
