import { Rating } from '../../domain/entities/rating-entitiy';
import { RatingRepository } from '../../domain/repositories/rating-repository';
import { driverRating } from '../datasources/driver-rating';
import { feedbackRating } from '../datasources/feedback-rating';
import { getRating } from '../datasources/get-rating';
import { improvementRating } from '../datasources/improvement-rating';
import { starRating } from '../datasources/star-rating';

export class RatingRepositoryImpl implements RatingRepository {
  async GetRating(tracking_id: string): Promise<Rating> {
    const result = await getRating(tracking_id);

    return new Rating(result);
  }

  async StarRating(tracking_id: string, rating: number): Promise<Rating> {
    const result = await starRating(tracking_id, rating);

    return new Rating(result);
  }

  async FeedbackRating(tracking_id: string, feedback: string): Promise<Rating> {
    const result = await feedbackRating(tracking_id, feedback);

    return new Rating(result);
  }

  async ImprovementRating(tracking_id: string, values: string[]): Promise<Rating> {
    const result = await improvementRating(tracking_id, values);

    return new Rating(result);
  }

  async DriverRating(tracking_id: string, value: string): Promise<Rating> {
    const result = await driverRating(tracking_id, value);

    return new Rating(result);
  }
}
