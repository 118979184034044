class Geopoint {
  latitude: number;
  longitude: number;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export class Driver {
  id: string;
  name: string;
  location: Geopoint;

  constructor(args: any) {
    this.id = args.id;
    this.name = args.name;
    this.location = new Geopoint(args.location.latitude, args.location.longitude);
  }
}
