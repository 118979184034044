// @ts-check
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';

import { LoadingComponent } from '../components/loading-component';
import { getTrackingDetailsAction } from '../redux/actions/tracking-actions';
import OrderTrackingPage from '../../../order/presentation/pages/order-tracking-page';
import NavBarComponent from '../../../../core/routes/components/navbar-component';

import { PageNotFoundComponent } from '../../../../core/routes/components/page-not-found-component';
import RatingPage from '../../../rating/presentation/pages/rating-page';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  space: {
    marginTop: '3rem'
  }
}));

function Tracking({ uid }) {
  const [tracking, setTracking] = useState('track');

  const { order, success } = useSelector((state) => state.order);

  useEffect(() => {
    if (success) {
      const status = order.status || 'loading';

      if (status === 'delivered') {
        setTracking('rate');
      }

      if (!['in_transit', 'arrived', 'delivered'].includes(status)) {
        setTracking('default');
      }
      console.log(status);
    }
  }, [success, order]);

  switch (tracking) {
    case 'loading':
      return <LoadingComponent />;
    case 'track':
      return <OrderTrackingPage />;
    case 'rate':
      return <RatingPage />;

    default:
      return <PageNotFoundComponent />;
  }
}

export default function TrackingPage() {
  // @ts-ignore
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { tracking, loading, error, success } = useSelector((state) => state.tracking);

  useEffect(() => {
    dispatch(getTrackingDetailsAction(id));
  }, [dispatch, id]);

  const [uid, setUid] = React.useState(null);

  const login = () => {
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        // Signed in..
        console.log('Signed in');
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
      });
  };

  // anonymous login
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      login();
      if (user) {
        const { uid } = user;
        // @ts-ignore
        setUid(uid);
        console.log('uid - ', uid);
      } else {
        // Authentication.signInAnonymously();
        console.log('signed out');
      }
    });
  }, []);

  return (
    <>
      <React.Fragment>
        <CssBaseline />

        {loading && <LoadingComponent />}
        {!loading && success && (
          <>
            {!tracking.active && (
              <>
                <PageNotFoundComponent />
              </>
            )}

            {tracking.active && (
              <>
                <NavBarComponent />
                <div className={classes.space}>
                  <Tracking uid={uid} />
                </div>
              </>
            )}
          </>
        )}
        {!loading && error && <PageNotFoundComponent />}
      </React.Fragment>
    </>
  );
}
