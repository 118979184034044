import { Driver } from '../../domain/entities/driver-entity';
import { Order } from '../../domain/entities/order-entity';
import { OrderRepository } from '../../domain/repositories/order-repository';
import firebase from '../../../../core/config/firestore';
import { publishSubscribe } from '../datasources/publish-subscribe';
import { PublisherSubscriber } from '../../domain/entities/publisher-subscriber-entity';

const db = firebase.firestore();
const ps = publishSubscribe();

export class OrderRepositoryImpl implements OrderRepository {
  GetOrder(client_id: string, order_id: string): PublisherSubscriber {
    const doc = db.collection('clients').doc(client_id).collection('orders').doc(order_id);

    doc.onSnapshot((snapshot) => {
      const order = { id: snapshot.id, ...snapshot.data() };

      ps.publish('order', new Order(order));
    });

    return new PublisherSubscriber(ps);
  }

  GetDriver(driver_id: string): Promise<Driver> {
    throw new Error('Method not implemented.');
  }

  LiveLocation(driver_id: string): PublisherSubscriber {
    const doc = db.collection('drivers').doc(driver_id);

    doc.onSnapshot((snapshot) => {
      // console.log(`Received doc snapshot update driver: ${snapshot}`);
      const driver = { id: snapshot.id, ...snapshot.data() };

      ps.publish('live-location', new Driver(driver));
    });

    return new PublisherSubscriber(ps);
  }
}
