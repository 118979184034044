import { Tracking } from '../../domain/entities/tracking-entity';
import { TrackingRepository } from '../../domain/repositories/tracking-repository';
import { getTrackingDetails } from '../datasources/get-tracking-details';

export class TrackingRepositoryImpl implements TrackingRepository {
  async GetTrackingDetails(tracking_id: string): Promise<Tracking> {
    const result = await getTrackingDetails(tracking_id);

    return new Tracking(result, result.organization, result.driver, result.ratings);
  }
}
