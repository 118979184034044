import { ORDER_LOAD_REQUEST, ORDER_LOAD_SUCCESS, ORDER_LOAD_FAILURE } from '../types/order-types';

const initialState = {
  loading: false,
  success: false,
  error: null,
  order: null
};

function order(state = initialState, action: any = null) {
  switch (action.type) {
    case ORDER_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };

    case ORDER_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };

    case ORDER_LOAD_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
        success: true,
        error: null
      };

    default:
      return state;
  }
}

export default order;
