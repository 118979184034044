export const publishSubscribe = () => {
  const subscribers = {};

  const publish = (eventname: string, data: any) => {
    if (!Array.isArray(subscribers[eventname])) {
      return;
    }
    subscribers[eventname].forEach((callback) => {
      callback(data);
    });
  };

  const subscribe = (eventname: string, callback: any) => {
    if (!Array.isArray(subscribers[eventname])) {
      subscribers[eventname] = [];
    }
    subscribers[eventname].push(callback);

    // unsubscribe
    const index = subscribers[eventname].length - 1;

    return {
      unsubscribe() {
        subscribers[eventname].splice(index, 1);
      }
    };
  };

  return { publish, subscribe };
};
