// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfigProd = {
  apiKey: 'AIzaSyCuJzI57ThIdM-5jWcYJJH89TjCApGBZf0',
  authDomain: 'cb-prod-297913.firebaseapp.com',
  projectId: 'cb-prod-297913',
  storageBucket: 'cb-prod-297913.appspot.com',
  messagingSenderId: '136986454700',
  appId: '1:136986454700:web:892423eef2948d62d1cd09',
  measurementId: 'G-QK7N67TY29'
};
