/**
 * Function that calculates the distance between 2 points using the Haversine method
 * @param {Point} point_1
 * @param {Point}  point_2
 *
 * @returns {any} distance
 */
export const computeDistance = (point_1: any, point_2: any): any => {
  try {
    const pi = Math.PI;

    const phi1 = point_1.latitude * (pi / 180);
    const phi2 = point_2.latitude * (pi / 180);

    const deltaphi = (point_2.latitude - point_1.latitude) * (pi / 180);
    const deltalambda = (point_2.longitude - point_1.longitude) * (pi / 180);

    const a =
      Math.sin(deltaphi / 2) * Math.sin(deltaphi / 2) +
      Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltalambda / 2) * Math.sin(deltalambda / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    /* Earth's raduis in meters */
    const radius = 6371e3;

    return { distance: radius * c, units: 'meters' };
  } catch (error) {
    throw error;
  }
};
