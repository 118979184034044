import firebase from 'firebase';
import { firebaseConfigDev } from './firebase-dev';
import { firebaseConfigProd } from './firebase-prod';

const APP_ENV = process.env.REACT_APP_ENV;

const firebaseConfig = APP_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
