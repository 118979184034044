class Driver {
  id: string;
  name: string;
  image: string;

  constructor(args: any) {
    this.id = args.id;
    this.name = args.name;
    this.image = args.image;
  }
}

class Organization {
  id: string;
  name: string;
  logo: string;

  /**
   * Organization entity constructor
   * @constructor
   * @param {string} id
   * @param {string} name
   * @param {string} organization
   * @param {string} logo
   */
  constructor(id: string, name: string, logo: string) {
    this.id = id;
    this.name = name;
    this.logo = logo;
  }
}

export class Tracking {
  id: string;
  active: boolean;
  client_id: string;
  trip_id: string;
  order_id: string;
  driver: Driver;
  organization: Organization;
  ratings: string[];

  constructor(args: any, organization: any, driver: any, ratings: string[] = []) {
    this.id = args.id;
    this.active = args.active;
    this.client_id = args.client_id;
    this.trip_id = args.trip_id;
    this.order_id = args.order_id;
    this.driver = driver && new Driver(driver);
    this.organization = new Organization(organization.id, organization.name, organization.logo);
    this.ratings = ratings.map((rating: string) => rating);
  }
}
