// @ts-check
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { LoadingComponent } from '../../../tracking/presentation/components/loading-component';

import { DriverRatingComponent } from '../components/driver-rating-component';
import { FeedbackRatingComponent } from '../components/feedback-rating-component';
import { StarRatingComponent } from '../components/star-rating-component';
import { getRatingAction } from '../redux/actions/rating-actions';
import { getOrderAction } from '../../../order/presentation/redux/actions/order-actions';
import { InformationComponent } from '../../../order/presentation/components/information-component';
import { NonTrackingMapComponent } from '../../../order/presentation/components/non-tracking-map-component';

function RatingState({ step = -1 }) {
  switch (step) {
    case 0:
      return <StarRatingComponent />;
    case 1:
      return <FeedbackRatingComponent />;
    case 2:
      return <DriverRatingComponent />;

    default:
      // return <StarRatingComponent />;
      return null;
  }
}

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0
  },
  overlay: {
    position: 'relative',
    top: theme.spacing(4)
  },
  overlayBottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0
  }
}));

export default function RatingPage() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { tracking } = useSelector((state) => state.tracking);
  const { rating } = useSelector((state) => state.rating);
  const order = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getOrderAction(tracking.client_id, tracking.order_id));
    dispatch(getRatingAction(tracking.id));
  }, [dispatch, tracking]);

  return (
    <div>
      {!order.success && <LoadingComponent />}
      {order.success && rating && (
        <>
          <div className={classes.media}>
            <NonTrackingMapComponent />
          </div>

          <div className={classes.overlay}>
            <InformationComponent />
            <RatingState step={rating.step} />
          </div>
        </>
      )}
    </div>
  );
}
