// @ts-check
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { TrackingMapComponent } from '../components/tracking-map-component';
import { InformationComponent } from '../components/information-component';

import { DriverComponent } from '../components/driver-component';
import { getDriverAction, getOrderAction } from '../redux/actions/order-actions';
import { LoadingComponent } from '../../../tracking/presentation/components/loading-component';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0
  },
  overlay: {
    position: 'relative',
    top: theme.spacing(4)
  },
  overlayBottom: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0
  }
}));

function OrderTracking() {
  const classes = useStyles();

  const { tracking } = useSelector((state) => state.tracking);
  const order = useSelector((state) => state.order);

  return (
    <div>
      <div className={classes.media}>
        <TrackingMapComponent />
      </div>

      {order.success && (
        <>
          <div className={classes.overlay}>
            <InformationComponent />
            <DriverComponent driver={tracking.driver} />
          </div>
        </>
      )}
    </div>
  );
}

export default function OrderTrackingPage() {
  const dispatch = useDispatch();

  const { tracking } = useSelector((state) => state.tracking);
  const order = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getOrderAction(tracking.client_id, tracking.order_id));
    dispatch(getDriverAction(tracking.driver.id));
  }, [dispatch, tracking]);

  return (
    <div>
      {order.loading && <LoadingComponent />}
      <OrderTracking />
    </div>
  );
}
