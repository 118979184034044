// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Grid from '@material-ui/core/Grid';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

const useStyles = makeStyles((theme) => ({
  driver: {
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'rgb(255 255 255 / 90%)',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  paperorg: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    // marginBottom: theme.spacing(1),
    textAlign: 'center',
    height: '2rem',
    objectFit: 'contain'
  },
  eta: {
    textAlign: 'right'
  },
  logo: {
    height: '1.5rem',
    objectFit: 'contain'
  }
}));

export function DriverComponent({ driver }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container>
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div>
              <List className={classes.driver}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={`${driver.name} `}
                      src={driver.image || '/static/images/avatar/1.jpg'}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={`${driver.name}`} secondary={null} />

                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      <MotorcycleIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
